<template>
  <v-container>
    <v-row class="fill-height">
      <v-col>
        <v-card>
          <v-card-title>
            <v-icon
              @click="pushRouteToView('employees')"
              class="mr-4"
              style="cursor: pointer"
            >
              mdi-arrow-left
            </v-icon>
            <v-icon>mdi-hair-dryer-outline</v-icon>
            Funcionário
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              :to="{ name: 'employeeEdit', params: { id: employee.id } }"
            >
              <v-icon left>mdi-pencil</v-icon>
              Editar
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="4" sm="6"> <b>Nome:</b> {{ employee.name }} </v-col>
              <v-col md="4" sm="6"> <b>CPF:</b> {{ employee.cpf }} </v-col>
              <v-col md="12" sm="12">
                <b>Categorias:</b> {{ employee.categories }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { EMPLOYEE_GET } from "@/store/actions/employees.type";
import { EMPLOYEE_RESET } from "@/store/mutations/employees.type";

export default {
  name: "employee",

  // data: () => ({
  //   selected: [],
  //   headers: [
  //     { text: "Nome", value: "Name", align: "center" },
  //     { text: "Cpf", value: "CPF", align: "center" },
  //     { text: "Categorias", value: "categories", align: "center" },
  //     { text: "Valor", value: "value", align: "center" },
  //     { text: "Valor adicional", value: "additionalValue", align: "center" },
  //     { text: "Razão adicional", value: "additionalReason", align: "center" },
  //     { text: "Editar", value: "actions", align: "center", sortable: false }
  //   ]
  // }),

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(EMPLOYEE_RESET);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(EMPLOYEE_RESET),
      store.dispatch(EMPLOYEE_GET, to.params.id)
    ]).then(() => {
      next();
    });
  },

  methods: {
    pushRouteToView(route) {
      this.$router.push({ name: route });
    }
  },

  computed: {
    ...mapGetters(["employee", "checkUsers"])
  }
};
</script>
